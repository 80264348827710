import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';

import Card from './AboutCard/Card';
import Icon from '../../assets/images/icon.png';
import AboutIcon1 from '../../assets/images/about_icon_2.png';
import AboutIcon from '../../assets/images/about_icon.svg';
import Google from '../../assets/images/backgrounds/google.svg';
import Wired from '../../assets/images/backgrounds/wired.svg';
import NYTimes from '../../assets/images/backgrounds/ny-times.svg';
import Phone1 from '../../assets/images/backgrounds/about_1.png';
import Phone2 from '../../assets/images/backgrounds/about_2.png';
import Phone3 from '../../assets/images/backgrounds/about_bg.png';
import './About.scss';
import ScrollMagic from 'scrollmagic';

const $ = window.$

const items = [
    {
        // src: Phone1,
        altText: 'Slide 1',
        captionHeader: 'Booking Made Easy',
        caption: 'Get the same hotels at the same rest.',
    },
    {
        // src: Phone3,
        altText: 'Slide 2',
        caption: 'Beautiful rooms and affordable prices.',
        captionHeader: 'Great Rooms, Great Prices',
    },
    {
        // src: Phone2,
        altText: 'Slide 3',
        captionHeader: `Become a Beacon of Hope`,
        caption: 'Make the world better you’re at it.',
    }
];
export default class About extends Component {
    state = {
        activeIndex: 0,
        animating: false,
        counter: 0
    }
    componentDidMount() {
        let size = window.matchMedia("(max-width:575px)");
        let sizeM = window.matchMedia("(min-width:767px) and (max-width:992px)");
        let sizeX = window.matchMedia("(min-width: 1024px) and (max-width: 1366px)");
        if (size.matches) {
            const carouselCaption = document.querySelectorAll(".carousel-caption");
            let carouselCaption1 = carouselCaption[0].classList.add('carousel-caption1');
            let carouselCaption2 = carouselCaption[1].classList.add('carousel-caption2');
            let carouselCaption3 = carouselCaption[2].classList.add('carousel-caption3');

            let controller = new ScrollMagic.Controller();

            // build scene
            let scene = new ScrollMagic.Scene({
                triggerElement: "#trigger4",
                duration: "50%"
            })
                .setPin(".AboutWrapper")
                .offset(350)
                .addTo(controller);

            const self = this

            scene.on("progress", function (event) {
                switch (event.scrollDirection) {
                    case "FORWARD": {
                        if (event.state == "DURING") {
                            $(".carousel").carousel('next')

                        }
                    }
                    case "REVERSE": {
                        $(".carousel").carousel('prev')
                    }
                }
            });
        }
        else if (sizeM.matches) {
            let controller = new ScrollMagic.Controller();

            // build scene
            let scene = new ScrollMagic.Scene({
                triggerElement: "#trigger4",
                duration: "50%"
            })
                .setPin(".AboutWrapper")
                .offset(350)
                .addTo(controller);

            const self = this

            scene.on("progress", function (event) {
                switch (event.scrollDirection) {
                    case "FORWARD": {
                        if (event.state == "DURING") {
                            $(".carousel").carousel('next')

                        }
                    }
                    case "REVERSE": {
                        $(".carousel").carousel('prev')
                    }
                }
            });
        }
        else if (sizeX.matches) {
            let controller = new ScrollMagic.Controller();
            let scene = new ScrollMagic.Scene({
                triggerElement: "#trigger4",
                duration: "100%"
            })
                .setPin(".About")
                .offset(350)
                .addTo(controller);
            const self = this

            scene.on("start", (e) => {
                self.setState({ counter: 0 })
                $('.AboutCard').each((i, elem) => {
                    $(elem).hover(() => {
                        switch (i) {
                            case 0: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                self.setState({ counter: 10 })
                                break;
                            }
                            case 1: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone3');
                                document.querySelector('.AboutBG').classList.add('AboutPhone2');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                self.setState({ counter: 20 })
                                break;
                            }
                            case 2: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone3');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 1;
                                self.setState({ counter: 50 })
                                break;
                            }
                        }
                    })
                })
                scene.on("progress", function (event) {
                    const slide = () => {
                        switch (true) {
                            case self.state.counter >= 0 && self.state.counter < 20: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                break;
                            }
                            case self.state.counter >= 20 && self.state.counter < 50: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone3');
                                document.querySelector('.AboutBG').classList.add('AboutPhone2');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                break;
                            }
                            case self.state.counter >= 50 && self.state.counter < 70: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone3');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 1;
                                break;
                            }
                            case self.state.counter >= 55: {
                                // scene.removePin("#about")
                            }
                            default: {

                                break;
                            }
                        }
                    }
                    switch (event.scrollDirection) {
                        case "FORWARD": {
                            if (event.state == "DURING") {
                                slide()
                                self.setState({ counter: ++self.state.counter })
                                break;
                            }

                        }
                        case "REVERSE": {
                            slide()
                            self.setState({ counter: --self.state.counter })
                            break;
                        }
                    }
                });
            })
        }
        else {
            let controller = new ScrollMagic.Controller();

            // build scene
            let scene = new ScrollMagic.Scene({
                triggerElement: "#trigger4",
                duration: "320%"
            })
                .setPin(".About")
                .offset(350)
                .addTo(controller);

            const self = this

            scene.on("start", (e) => {
                self.setState({ counter: 0 })
                $('.AboutCard').each((i, elem) => {
                    $(elem).hover(() => {
                        switch (i) {
                            case 0: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                self.setState({ counter: 10 })
                                break;
                            }
                            case 1: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone3');
                                document.querySelector('.AboutBG').classList.add('AboutPhone2');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                self.setState({ counter: 20 })
                                break;
                            }
                            case 2: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone3');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 1;
                                self.setState({ counter: 50 })
                                break;
                            }
                        }
                    })
                })
                scene.on("progress", function (event) {
                    const slide = () => {
                        switch (true) {
                            case self.state.counter >= 0 && self.state.counter < 20: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                break;
                            }
                            case self.state.counter >= 20 && self.state.counter < 50: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone3');
                                document.querySelector('.AboutBG').classList.add('AboutPhone2');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 1;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 0.04;
                                break;
                            }
                            case self.state.counter >= 50 && self.state.counter < 70: {
                                document.querySelector('.AboutBG').classList.remove('AboutPhone2');
                                document.querySelector('.AboutBG').classList.add('AboutPhone3');
                                document.querySelectorAll('.AboutCard')[0].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[1].style.opacity = 0.04;
                                document.querySelectorAll('.AboutCard')[2].style.opacity = 1;
                                break;
                            }
                            case self.state.counter >= 55: {
                                // scene.removePin("#about")
                            }
                            default: {

                                break;
                            }
                        }
                    }
                    switch (event.scrollDirection) {
                        case "FORWARD": {
                            if (event.state == "DURING") {
                                slide()
                                self.setState({ counter: ++self.state.counter })
                                break;
                            }

                        }
                        case "REVERSE": {
                            slide()
                            self.setState({ counter: --self.state.counter })
                            break;
                        }
                    }
                });
            })
        }
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    next = (newIndex) => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: newIndex });
    }
    previous = (newIndex) => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }
    slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={this.setState({ animating: true })}
                onExited={this.setState({ animating: false })}
                key={item.src}
            >
                {/* <img src={item.src} className="" alt={item.altText} /> */}
                <CarouselCaption captionText={item.caption} captionHeader={item.captionHeader} />
            </CarouselItem>
        );
    });

    render() {
        let size = window.matchMedia("(max-width:575px)");
        let sizeM = window.matchMedia("(min-width:767px) and (max-width:992px)");
        if (size.matches) {

            return (
                <>
                    <div id="trigger4"></div>
                    <div className="AboutWrapper">
                        <div className="AboutMobile1">
                            <Carousel className="text-center"
                                activeIndex={this.state.activeIndex}
                                next={this.props.next}
                            >
                                <CarouselIndicators items={items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                                {this.slides}
                            </Carousel>
                        </div>
                        <div className="AboutMobile2">
                            <div className="MobileCard">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <div className="about-icon">
                                            <img src={Google} className="w-50" alt="google logo" />
                                        </div>
                                        <div className="about-icon">
                                            <img src={Wired} className="w-50" alt="wired logo" />
                                        </div>
                                        <div className="about-icon">
                                            <img src={NYTimes} className="w-50" alt="Newyork times logo" />
                                        </div> */}
                                    </div>
                                    <div className="col-6">
                                        <div className="chip-bg"></div>
                                    </div>
                                    <div className="col-12">
                                        <div className="content">
                                            <h3>Guaranteed payment security</h3>
                                            <p>
                                                Get the same hotels at the same rest, and make the world better you’re at it.
                                                That’s because Sheltr invest 1/3 of its profits into the fight against homelessness.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (sizeM.matches) {
            return (
                <>
                    <div id="trigger4"></div>
                    <div className="AboutWrapper">
                        <div className="AboutMobile1">
                            <Carousel className="text-center"
                                activeIndex={this.state.activeIndex}

                            >
                                <CarouselIndicators items={items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                                {this.slides}
                            </Carousel>
                        </div>
                        <div className="AboutMobile2">
                            <div className="MobileCard">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <div className="about-icon">
                                            <img src={Google} className="w-50" alt="google logo" />
                                        </div>
                                        <div className="about-icon">
                                            <img src={Wired} className="w-50" alt="wired logo" />
                                        </div>
                                        <div className="about-icon">
                                            <img src={NYTimes} className="w-50" alt="NewYork Times logo" />
                                        </div> */}
                                    </div>
                                    <div className="col-6">
                                        <div className="chip-bg"></div>
                                    </div>
                                    <div className="col-12">
                                        <div className="content">
                                            <h3>Guaranteed payment security</h3>
                                            <p>
                                                Get the same hotels at the same rest, and make the world better you’re at it.
                                                That’s because Sheltr invest 1/3 of its profits into the fight against homelessness.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <div id="trigger4"></div>
                    <div className="About" id="about">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <div className="PhoneOverlay"></div>
                                    <div className="AboutBG AboutPhone"></div>
                                </div>
                                <div className="col-md-8">
                                    <div className="about-details">
                                        <Card
                                            heading="Booking Made Easy"
                                            icon={Icon}
                                            text="Search and book hotels tailored for you in less than 30secs." />
                                        <Card

                                            heading="Great Rooms, Great Prices"
                                            icon={AboutIcon1}
                                            text="Beautiful rooms and affordable prices." />
                                        <Card

                                            heading="Become a Beacon of Hope"
                                            icon={AboutIcon}
                                            text="Make the world better you’re at it." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}
