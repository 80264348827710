import React from 'react';

import "./left.scss"

const LeftCard = () => {
    return (
        <div  className="heading left moveText">
            <h3>Not just another booking app</h3>
            <p>
                Get the same hotels at the same rates as the "other guys."
                The difference? You get to help someone while doing it,
                because Sheltr invests 1/3 of its profits into the fight to end homelessness.
			</p>
        </div>
    )
}
export default LeftCard;