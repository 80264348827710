import React, { Component } from 'react';

import LeftCard from '../LeftCard/LeftCard';
import RightCard from '../RightCard/RightCard';

import './Phone.scss';

const size = window.matchMedia("(max-width: 767px)");
const sizeM = window.matchMedia("(min-width:767px) and (max-width:992px)");
export default class Phone extends Component {
    componentDidMount() {

        // for mobile screen sizes
        if (size.matches) {}
        // FOR TABLET SCREENS
        else if (sizeM.matches) {}
        //FOR DESKTOP SCREEN SIZES
        else {
            // window.addEventListener("scroll", (ev) => {
            //     if (window.scrollY >= 50) {
            //         document.querySelector('.xp').classList.remove('Phone');
            //         document.querySelector('.xp').classList.add('Phone_2');
            //         if (window.scrollY >= 220 && window.scrollY <= 250) {
            //             document.querySelectorAll('.moveText').forEach(v => v.style.transform = `translateY(-${window.scrollY * 0.2}px)`);
            //             let size = window.matchMedia("(max-width:575px)");
            //             if (size.matches) {
            //                 document.querySelectorAll('.moveText').forEach(v => v.style.transform = `translateY(-${window.scrollY * 0.4}px)`);
            //             }
            //         } else {
            //             document.querySelectorAll('.moveText').forEach(v => v.style.transform = `translateY(10px)`)
            //         }
            //         document.querySelector('.xp').style.transform = `translateY(2%)`
            //         if (window.scrollY >= 350) {
            //             // document.querySelector('.xp').classList.remove('Phone_2');
            //             // document.querySelector('.xp').classList.add('Phone_3');

            //             if (window.scrollY >= 1000) {
            //                 // document.querySelector('.xp').style.transform = `translateY(-${window.scrollY * 0.5}px)`
            //                 // document.querySelector('.xp').style.opacity = `${100 - window.scrollY}`

            //                 if (window.scrollY >= 1200) {
            //                     document.querySelector('.xp').style.transform = `translateY(${window.scrollY}px)`
            //                 }
            //             } else {
            //                 document.querySelector('.xp').style.opacity = `${100 + window.scrollY}`
            //             }

            //         } else {
            //             // document.querySelector('.xp').classList.remove('Phone_3');
            //             // document.querySelector('.xp').classList.add('Phone_2');
            //             if (window.scrollY <= 450 && window.scrollY > 400) {
            //                 document.querySelector('.xp').style.transform = `translateY(${window.scrollY}px)`
            //             }
            //         }
            //     } else {
            //         document.querySelector('.xp').classList.remove('Phone_2');
            //         document.querySelector('.xp').classList.add('Phone');
            //         document.querySelector('.xp').style.transform = `translateY(5%)`
            //         document.querySelectorAll('.moveText').forEach(v => v.style.transform = `translateY(-50px)`)
            //         document.querySelector('.xp').style.opacity = 1
            //     }
            // })
        }

    }
    render() {
        if(size.matches){
            return (
                <div className="Phone xp">
                </div>
            )
        } else{
            return (
                <section className="Phone demo-section" id="pin1">
                        <div className="container-fluid ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4">
                                    <LeftCard />
                                </div>
                                <div className="col-lg-4" >
                                    <div className="mobile-app-demo"></div>  
                                </div>
                                <div className="col-lg-4">
                                    <RightCard />
                                </div>
                            </div>
                        </div>

                    </section>
            )
        }
    }
}