import React from 'react';

import "./style.scss"

const PhoneMobile = () => {
    
    return (
        <section className="PhoneMobile" id="pin1">
            {/* <div className="PhoneTop d-none d-md-block" id="pin1"></div>
            <div className="PhoneTop d-block d-md-none" id="pin1"></div> */}
            <div className="container-fluid pt-5 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <div className="Top">
                            <h3>You get to be an angel.</h3>
                            <p>
                                Get the same hotels at the same rest,
                                and make the world better you’re at it.
                                That’s because Sheltr invest 1/3 of its profits into the fight against homelessness.
                            </p>
                        </div>
                        <div className="Mid"></div>
                        <div className="Bottom">
                            <h3>Not just another booking app</h3>
                            <p>
                                Get the same hotels at the same rest,
                                and make the world better you’re at it.
                                That’s because Sheltr invest 1/3 of its profits into the fight against homelessness.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}
export default PhoneMobile;