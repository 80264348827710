import React from 'react';

import Icon from '../../assets/images/backgrounds/notify.svg';
import Android from '../../assets/images/play.svg';
import IOS from '../../assets/images/app.svg';

import './Subscribe.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const endpoint = "https://us-central1-sheltr-web.cloudfunctions.net/mail/add"

const $ = window.$


// Call it once in your app. At the root of your app is the best place
toast.configure()

$(function () {
    $('form').submit(function (e) {
        e.preventDefault()
    });
})

const onSubmit = (e) => {
    const email = $("input").val()
    e.preventDefault()
    fetch(`${endpoint}?email=${email}`, {
        method: 'POST', headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            // $('#success').toast("show")
            toast.success("Thank you for subscribing to Sheltr Beta.", {
                position: toast.POSITION.TOP_CENTER
            });
            $("input").val("")
        }).catch((err) => {
            // $('#failed').toast("show")
            toast.error("There was a problem. Confirm that your email address is correct!", {
                position: toast.POSITION.TOP_LEFT
            });
        });
    return false;
}

const Subscribe = () => {
    return (
        <>
            <div className="Subscribe">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <h1 className="text-center">Sign up for our  <br /> Beta </h1>
                            <h3>And we’ll notify you when we launch.</h3>
                            <form onSubmit={onSubmit} className="row">
                                <input type="email" className="form-control col-md-7" placeholder="Enter your email to get notified" required />
                                <button type="submit" className="Subscribe-btn col-md-4">
                                    <img src={Icon} alt="button" />
                                    Notify Me !
                            </button>
                            </form>
                            <div className="mobile-links">
                                <a className="mr-5e">
                                    <img src={IOS} alt="app store link" />
                                </a>
                                <a >
                                    <img src={Android} alt="play store link" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Subscribe;