import React, { Component } from 'react';

import ScrollMagic from 'scrollmagic';
import { TweenMax, Back } from "gsap/all";
import "../../lib/animation.gsap"
import './Tour.scss';
// import * as $ from "jquery"

const best = require("../../assets/images/BestinClass.png")
const fight = require("../../assets/images/FightHomelessness.png")
const impact = require("../../assets/images/impact.png")

const $ = window.$

class Tour extends Component {
    componentDidMount() {
        let scrollPos = 0;
        let size = window.matchMedia("(max-width:575px)");
        let sizeM = window.matchMedia("(min-width:767px) and (max-width:992px)");

        if (size.matches) { }
        else if (sizeM.matches) { }
        else {
            let controller = new ScrollMagic.Controller();

            // build scene
            let scene = new ScrollMagic.Scene({
                triggerElement: "#trigger3",
                duration: "100%"
            })
                .setPin("#scrollTour")
                .setClassToggle(".scrollmagic-pin-spacer", "d-lg-block")
                .offset(290)
                .addTo(controller);

            $("#tourslider").carousel('pause')
            $("#tourslider").on('slid.bs.carousel', function (e) {
                if (e.from == 2) {
                    // scene.removePin(true)
                    // $("#tourslider").carousel('dispose')
                }
                const bg = $(".TourBG")
                console.log(impact)
                switch (e.to) {
                    case 0: {
                        bg.css("background-image", `url(${impact})`);
                        break;
                    }
                    case 1: {
                        bg.css("background-image", `url(${best})`);
                        break;
                    }
                    case 2: {
                        bg.css("background-image", `url(${fight})`);
                        break;
                    }
                    default: {
                        bg.css("background-image", `url(${impact})`);
                        break;
                    }
                }
            })

            scene.on("progress", function (event) {
                switch (event.scrollDirection) {
                    case "FORWARD": {
                        if (event.state == "DURING") {
                            $("#tourslider").carousel('next')

                        }
                    }
                    case "REVERSE": {
                        $("#tourslider").carousel('prev')
                    }
                }
            });
        }



    }

    render() {
        return (
            <>
                <div id="trigger3" className="d-none d-lg-block"></div>
                <div className="Tour desktop d-none d-lg-block" id="scrollTour">
                    <div className="container desktop" >
                        <div className="row">
                            <div className="col-md-8">
                                <div id="tourslider" className="tour-info carousel slide carousel-fade tourslide" data-ride="false" data-pause >
                                    <div className="carousel-inner">
                                        <div data-index="0" className="carousel-item active ">
                                            <p className="d-block w-100">Generating Impact <br />as you book with us.</p>
                                        </div>
                                        <div data-index="1" className="carousel-item ">
                                            <p className="d-block w-100">Best-in-class <br /> hotel booking experience.</p>
                                        </div>
                                        <div data-index="2" className="carousel-item">
                                            <p className="d-block w-100" >Vacations that help <br /> fight homelessness.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div>
                                    <div className="tour-overlay"></div>
                                    <div className="TourBG"></div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="Tour mobile">
                    <div className="container-fluid mobile">
                        <div className="row">
                            <div className="col-6 pl-0">
                                <div className="ml--2">
                                    <div className="TourOverlay-m"></div>
                                    <div style={{backgroundImage: `url(${impact}`}} className="TourBG-m"></div>
                                </div>
                            </div>
                            <div className="col-6 self-center">
                                <p className="Tour-text"> Generating Impact <br />as you book with us.</p>
                            </div>
                        </div>
                        <div className="row mt--5e mid">
                            <div className="col-6 self-center">
                                <p className="Tour-text"> Best-in-class hotel <br />booking experience.</p>
                            </div>
                            <div className="col-6 pr-0">
                                <div className="mr--2">
                                    <div className="TourOverlay-m switch"></div>
                                    <div style={{backgroundImage: `url(${best}`}} className="TourBG-m"></div>
                                </div>
                            </div>

                        </div>
                        <div className="row mt--5e">
                            <div className="col-6 pl-0">
                                <div className="ml--2">
                                    <div className="TourOverlay-m"></div>
                                    <div style={{backgroundImage: `url(${fight}`}} className="TourBG-m"></div>
                                </div>
                            </div>
                            <div className="col-6 self-center">
                                <p className="Tour-text">Vacations that help <br /> fight homelessness.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default Tour;
