import React from 'react';
import "./right.scss"
const RightCard = () => {
    return (
        <div className="heading right moveText">
            <h3 className="text-left">You get to be an angel.</h3>
            <p className="text-left">
                Get the same hotels at the same rest,
                and make the world better you’re at it.
                That’s because Sheltr invest 1/3 of its profits into the fight against homelessness.
			</p>
        </div>
    )
}
export default RightCard;