import React, { Component } from 'react';

import ScrollMagic from 'scrollmagic';

import Phone from '../DescriptionRow/Phone/Phone';
import About from '../About/About';
import Testimonial from '../Testimonial/Testimonial';
import Tour from '../Tour/Tour';
import Subscribe from '../Subscribe/Subscribe';
import Footer from '../Footer/Footer';
import PhoneMobile from '../PhoneMobile/PhoneMobile';
import './style.scss';
const $ = window.$

let size = window.matchMedia("(max-width:767px)");
let sizeM = window.matchMedia("(min-width:767px) and (max-width:992px)");
let sizeX = window.matchMedia("(min-width: 1024px) and (max-width: 1366px)");
export default class bg extends Component {
    componentDidMount() {
        // // MOBILE
        if (size.matches) {
            let controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: "#trigger",
                duration: "180%"
            })
                .offset(300)
                .setPin("#pin1")
                .setClassToggle(".scrollmagic-pin-spacer", ".phoneBg")
                .addTo(controller)
            // window.addEventListener('scroll', () => {
            //     if (window.scrollY > 100 || window.scrollY > 10) {
            //         const phoneY = (window.scrollY * 2)
            //         if (phoneY >= 105) {
            //             document.querySelector(".Main-bg").style.transform = `translateY(-${window.scrollY + 10}px)`
            //             if (phoneY >= 806) {
            //                 document.querySelector(".desc").style.transform = `translateY(-${phoneY * 0.05}px)`
            //             } else {
            //                 document.querySelector(".desc").style.transform = `translateY(${window.scrollY}px)`
            //             }
            //         } else {
            //         }
            //     } else {
            //         document.querySelector(".Main-bg").style.transform = `translateY(${window.scrollY * 0.25}px)`
            //         document.querySelector(".Main-bg").style.opacity = `${100 + window.scrollY * 0.5}%`
            //         document.querySelector(".banner-text").style.opacity = `${100 + window.scrollY}%`
            //     }
            // })
        }
        else if (sizeM.matches) {
            let controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: "#trigger",
                duration: "100%"
            })
                .offset(450)
                .setPin("#pin1")
                .addTo(controller)
        }
        else if(sizeX.matches){
            let controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: "#trigger",
                duration: "80%"
            })
                .offset(600)
                .setPin("#pin1")
                .addTo(controller)
        }
        // // DESKTOP VERSION
        else {
            window.addEventListener("scroll", (e) => {
                if (window.scrollY >= 240) {
                    document.querySelector('.cursor').style.display = "none"
                }
                else {
                    document.querySelector('.cursor').style.display = "block";
                }
            })

            let controller = new ScrollMagic.Controller();
            var scene = new ScrollMagic.Scene({
                triggerElement: "#trigger",
                duration: "200%"
            })
                .setPin("#pin1")
                .offset(450)
                .addTo(controller)

            // scene.on("start", (e)=>{
            //     $("#pin1").offset({top: "1px"})
            // })

            // var scene2 = new ScrollMagic.Scene({
            //     triggerElement: "#trigger2",
            //     duration: "200%"
            // })
            //     .setPin("#pin2")
            //     .offset(100)
            //     .addTo(controller)
        }

    }
    render() {
        if (size.matches) {
            return (
                <div className="Wrapper">
                    <div className="Main-bg">
                        <div className="banner-text">
                            <h1 data-text="Lets Do More." className="lets">Lets Do More. </h1>
                            <span className="cursor"></span>
                            <p>Book your hotel, take your trip, & help along the way.</p>
                            <div className="line-wrapper">
                                <div className="line"></div>
                            </div>
                            <p className="explore-text">Explore</p>
                            <div onClick={() => {
                                $('html, body').animate({
                                    scrollTop: 600
                                }, 300);
                            }} className="explore text-center">
                                <span>
                                    <i className="fa fa-angle-up"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="trigger" ></div>
                    <PhoneMobile />
                    {/* <section className="desc"> */}
                        <Tour />
                        <About />
                        <Subscribe />
                        <Footer />
                    {/* </section> */}
                </div>
            )
        }
        else if (sizeM.matches || sizeX.matches) {
            return (
                <div className="Wrapper">
                    <div className="Main-bg">
                        <div className="banner-text">
                            <h1 data-text="Lets Do More." className="lets">Lets Do  More. </h1>
                            <span className="cursor"></span>
                            <p>Book your hotel, take your trip, & help along the way.</p>
                            <div className="line-wrapper">
                                <div className="line"></div>
                            </div>
                            <p className="explore-text">Explore</p>
                            <div onClick={() => {
                                $('html, body').animate({
                                    scrollTop: 600
                                }, 300);
                            }} className="explore text-center">
                                <span>
                                    <i className="fa fa-angle-up"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="trigger"></div>
                    <PhoneMobile />
                    {/* <section className="desc"> */}
                        <Tour />
                        <About />
                        <Subscribe />
                        <Footer />
                    {/* </section> */}
                </div>
            )
        }
        else {
            return (

                <div className="Wrapper">
                    <div className="Main-bg">
                        <div className="banner-text">
                            <h1 data-text="Lets Do More." className="lets">Lets Do More. </h1>
                            <span className="cursor"></span>
                            <p>Book your hotel, take your trip, & help along the way.</p>
                            <div className="line-wrapper">
                                <div className="line"></div>
                            </div>
                            <p className="explore-text">Explore</p>
                            <div onClick={() => {
                                $('html, body').animate({
                                    scrollTop: 600
                                }, 300);
                            }} className="explore text-center">
                                <span>
                                    <i className="fa fa-angle-up"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="trigger"></div>
                    {/* <div id="pin1">
                        <div className="Main-bg--sm" ></div>
                    </div>
                    <div id="trigger2"></div> */}
                   
                    <Phone />
                    <Tour />
                    <About />
                    {/* <Testimonial /> */}
                    <Subscribe />
                    <Footer />
                </div>
            )
        }

    }
}
