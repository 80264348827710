import React from 'react';
import Logo from '../../assets/images/logo_white.svg';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="Footer">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12">
                        <a className="navbar-brand" >
                            <img src={Logo} />
                        </a>
                    </div>
                    <div className="col-md-4 order-xs-2">
                        <p className="copyright">
                            Copyright &copy; {new Date().getFullYear()}
                        </p>
                    </div>
                    <div className="col-md-3 col-lg-2 order-xs-1">
                        <ul className="footer-nav">
                            <li>Investors</li>
                            <li>Policies</li>
                            <li>Contact</li>
                            <li>Careers</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;