import React from 'react';
import './Card.scss';

const Card = (props) => {
    return(
        <div className="AboutCard">
            <div className="row">
                <div className="col-md-3 pr-0">
                    <div className="Icon">
                        <img src={props.icon} className="w-100" alt="icon" />
                    </div>
                </div>
                <div className="col-md-8 pl-0">
                    <h4>{props.heading}</h4>
                    <p>{props.text}</p>
                </div>
            </div>
        </div>
    )
}
export default Card;