import React, { Component } from 'react';
import Header from './components/Header/Header';
import MainBG from './components/MainBg/MainBg';

class App extends Component {
	componentDidMount() {
		var h = document.querySelector('.Main-bg .lets');
		var p = h.getBoundingClientRect();
		var c = document.querySelector('.Main-bg .cursor');


		document.body.onmousemove = function (e) {
			/*Adjust the cursor position*/
			c.style.left = e.clientX - 20 + 'px';
			c.style.top = e.clientY - 20 + 'px';

			/*Adjust the clip-path*/
			h.style.setProperty('--x', (e.clientX - p.top) + 'px');
			h.style.setProperty('--y', (e.clientY - p.left) + 'px');
		}
	}
	render() {
		return (
			<>
				<div className="App">
					<Header />
					<main>
						<MainBG />
					</main>
				</div>
			</>

		);
	}
}

export default App;
