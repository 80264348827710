import React, { Component } from 'react';

import Logo from '../../assets/images/logo.svg';
import NavToggleIcon from '../../assets/images/nav_toggler.svg';
import '../../assets/scss/main.scss';
import './Header.scss';


const $ = window.$;


export default class header extends Component {
	componentDidMount() {
		const size = window.matchMedia("(max-width: 768px)");
		// for mobile screen sizes

		window.addEventListener("scroll", (e) => {
			if (size.matches) {
				if (window.scrollY > 160) {
					document.querySelector(".navbar").classList.add("shadow")
					document.querySelector(".navbar").classList.remove("no-shadow")
					document.querySelector(".navbar").style.opacity = `${100 + window.scrollY * 0.5}%`

				} else {
					document.querySelector(".navbar").classList.remove("shadow")
					document.querySelector(".navbar").classList.add("no-shadow")
				}
			}
			else {
				if (window.scrollY > 190) {
					document.querySelector(".navbar").classList.add("shadow")
					document.querySelector(".navbar").classList.remove("no-shadow")
					document.querySelector(".navbar").style.opacity = `${100 + window.scrollY * 0.5}%`

				} else {
					document.querySelector(".navbar").classList.remove("shadow")
					document.querySelector(".navbar").classList.add("no-shadow")
				}
			}

		})
	}
	showMobileNav = () => {
		const mobileNav = document.querySelector('.collapse');
		if(mobileNav){
			if (mobileNav.classList.contains('show')) {
				document.querySelector('.collapse').classList.remove('show');
				document.querySelector(".navbar").style.background = "transparent";
			} else {
				document.querySelector('.collapse').classList.add('show');
				document.querySelector(".navbar").style.background = "white";
			}
		}
		
	}
	render() {
		return (
			<header className="header">
				<nav className="navbar navbar-expand-lg fixed-top no-shadow">
					<div className="container">
						<a className="navbar-brand mobile">
							<img className="logo" src={Logo} alt="sheltr logo" />
						</a>
						<button onClick={this.showMobileNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav"
							aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
							<img src={NavToggleIcon} alt="navbar toggler" />
						</button>

						<div className="collapse navbar-collapse" id="basicExampleNav">
							<ul className="navbar-nav mr-auto social-header">
								<li className="nav-item">
									<a className="nav-link" href="https://m.facebook.com/Givesheltr">
										<i className="fa fa-facebook"></i>
									</a>
								</li>
								{/* <li className="nav-item">
									<a className="nav-link" href="#">
										<i className="fa fa-linkedin"></i>
									</a>
								</li> */}
								<li className="nav-item">
									<a className="nav-link" href="https://www.instagram.com/givesheltr">
										<i className="fa fa-instagram"></i>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="https://m.facebook.com/Givesheltr">
										<i className="fa fa-twitter"></i>
									</a>
								</li>
							</ul>
							<ul className="navbar-nav mr-auto">
								<a className="navbar-brand" href="#">
									<img className="logo" src={Logo} alt="sheltr logo" />
								</a>
							</ul>
							<ul className="navbar-nav ml-auto">
								<button onClick={() => {

									$('html, body').animate({
										scrollTop: $(".Subscribe").offset().top
									}, 1000);
								}} className="btn rounded-btn bg-white f-20 c-brand font-weight-bold brand-btn">Sign up <i className="fa fa-angle-right ml--4 font-weight-bold"></i></button>
							</ul>
						</div>

					</div>
				</nav>
			</header>
		)
	}
}
